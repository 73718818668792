@import 'sass';

.field > .selection.dropdown {
	border-radius: map-get($borders, borders_small_radius) !important;
	padding: 10px !important;
	flex-wrap: wrap;
}

.ui.selection.dropdown .menu {
	@include remove-scrollbar;
	border-bottom-left-radius: map-get($borders, borders_small_radius) !important;
	border-bottom-right-radius: map-get($borders, borders_small_radius) !important;
	max-height: 300px;
}

.ui.multiple.dropdown > .label {
	border-radius: map-get($borders, borders_small_radius) !important;
	padding: 10px 15px !important;
	border: solid 1px map-get($colors, color_primary) !important;
	box-shadow: none !important;
	white-space: nowrap !important;
	background-color: white !important;

	&,
	i {
		color: map-get($colors, color_secondary) !important;
	}
	i {
		margin-left: 15px !important;
	}
}
.field > .selection.dropdown {
	@include flex-center;
	padding: 10px 20px !important;
	margin-bottom: 10px;
}
