@import 'sass';

.Wrapper {
	@include shadow-dark-soft;
	min-height: 100vh;
	width: 100vw;
	background-color: map-get($colors, color_defaultBlack);
	position: relative;

	.Top {
		@include flex-end;
		position: inherit;
		z-index: 100;
		min-height: 60vh;
		padding: 0 0 130px 0;
		box-sizing: border-box;

		.MobileName {
			display: none;
		}

		.ProfilePic {
			img {
				@include shadow-default;
				width: 100%;
				position: inherit;
				border-radius: 100%;
				max-width: 220px !important;
				img {
					margin: 0 auto !important;
				}
			}
		}

		.Text {
			h1,
			p {
				color: white;
			}

			span {
				font-weight: 800;
			}

			h1 {
				@include banner-txt-default;
			}
			p {
				@include p-default;
				width: 75%;
				letter-spacing: 2px;
				font-weight: 100 !important;
			}
		}
	}

	.Bottom {
		background-color: map-get($colors, color_primary);
		height: 40vh;
		padding: 20px 15px;

		.Buttons {
			@include flex-center-center;
		}
	}

	// ================
	// >> TABLET
	// ================
	@media #{map-get($media, tablet_max)} {
		.Top {
			padding: 20px 10px 120px 10px;

			.ProfilePic {
				img {
					max-width: 180px !important;
				}
			}

			.Text {
				h1 {
					padding: 0;
				}
			}
		}
	}

	// ================
	// >> MOBILE
	// ================
	@media #{map-get($media, mobile)} {
		min-height: unset;
		.Top {
			min-height: unset !important;
			padding-bottom: 140px !important;
			.ProfilePic {
				@include flex-center-center;
				margin-top: 10px !important;
				margin-bottom: 15px !important;

				img {
					width: 35%;
				}

				.MobileName {
					display: unset;
					width: 65%;
					padding-left: 10px;

					h1 {
						color: white;
					}
				}
			}

			.Text {
				p {
					width: 100%;
					font-weight: 300 !important;
					letter-spacing: 1px;
					font-size: 7vw !important;
					line-height: 9.5vw !important;
				}
			}

			.DesktopName {
				display: none;
			}
		}
		.Bottom {
			height: unset;
			padding-bottom: 70px !important;

			.Buttons {
				@include flex-col-center;
			}
		}
	}
}
