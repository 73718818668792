@import 'sass';

.Wrapper {
	@include flex-center-center;
	width: 100%;
	min-height: 80vh;

	.Form {
		width: 100%;

		h2 {
			margin-bottom: 0px !important;
		}

		p {
			@include p-small;
			margin: 5px 0 20px 0;
		}
	}
}
