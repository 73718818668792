@import 'sass';

.Wrapper {
	@include flex-center-center;
	padding: 17px 12px 8px 45px;
	position: fixed;
	z-index: 802;
	bottom: 0px;
	transition: 0.2s;
	border-top-right-radius: 40px;
	// border-top-right-radius: map-get($borders, borders_small_radius);
	background-color: map-get($colors, color_themeBlack);

	svg {
		// color: map-get($colors, color_themeBlack);
		color: white;
		font-size: 35px !important;
		margin: 0 !important;
	}

	&[data-hide-back='1'] {
		left: -100%;
	}

	&[data-hide-back='0'] {
		left: 20px;
	}

	.Home {
		position: absolute;
		right: -100%;
		background-color: red;
	}

	@media #{map-get($media, cpu_min)} {
		display: none !important;
	}
}
