@import 'sass';

.Toggle {
	@include flex-center-center;
	@include hover-cursor;

	position: fixed;
	bottom: 0;
	z-index: 803;
	padding: 24px 22px 15px 16px !important;
	border: none;
	border-top-right-radius: 40px;
	background-color: map-get($colors, color_primary) !important;

	height: map-get($sizes, sizes_nav_m_toggle);
	width: map-get($sizes, sizes_nav_m_toggle);

	svg {
		margin-top: 4px;
		position: absolute;
		color: map-get($colors, color_themeBlack);
	}

	@media #{map-get($media, cpu_min)} {
		display: none !important;
	}
}
