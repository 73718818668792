@import 'sass';

.Container {
	@include flex-col-center-center;
	margin-top: map-get($sizes, sizes_nav_dt_short);
	min-height: 400px;
	padding: 45px 0;

	h1 {
		@include banner-txt-default;
	}

	p {
		@include p-default;
	}

	.Btn {
		&[data-is-mobile='1'] {
			display: none !important;
		}
	}

	@media #{map-get($media, tablet_max)} {
		margin-top: 0;
		padding: 45px 20px 0px 20px;

		h1 {
			padding: 0;
		}
	}

	@media #{map-get($media, mobile)} {
		p {
			margin-bottom: 40px;
		}
	}
}
