@import '../variables/';

@mixin no-select {
	-webkit-touch-callout: none !important; /* iOS Safari */
	-webkit-user-select: none !important; /* Safari */
	-khtml-user-select: none !important; /* Konqueror HTML */
	-moz-user-select: none !important; /* Old versions of Firefox */
	-ms-user-select: none !important; /* Internet Explorer/Edge */
	user-select: none !important; /* Non-prefixed version, currently
										supported by Chrome, Edge, Opera and Firefox */
}

@mixin remove-scrollbar {
	&::-webkit-scrollbar {
		display: none;
	}
}
