@import './sass/';

* {
	margin: 0;
	padding: 0;
	font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	color: map-get($colors, color_themeBlack);
}
