@import 'sass';

.TextInput,
.TextArea,
.FileInput,
.Dropdown {
	&,
	input,
	textarea {
		border-radius: map-get($borders, borders_small_radius) !important;
	}
	margin-bottom: 10px !important;
	&:focus {
		border-color: map-get($colors, color_primary) !important;
	}
}

.LabelLeft {
	border-top-left-radius: map-get($borders, borders_small_radius) !important;
	border-bottom-left-radius: map-get($borders, borders_small_radius) !important;
}

.LabelRight {
	border-top-right-radius: map-get($borders, borders_small_radius) !important;
	border-bottom-right-radius: map-get($borders, borders_small_radius) !important;
}

.FormTitle {
	color: map-get($colors, color_primary);
	font-size: 18px;

	&[data-size='med'] {
		font-size: 22px;
	}

	&[data-size='large'] {
		font-size: 26px;
	}
}

// ••••••••••••••••••••••••••••••••••••••••••••••••
// ==> Form Success Card
// ••••••••••••••••••••••••••••••••••••••••••••••••

.SuccessWrapper {
	@include flex-col-center-center;
	width: 100%;
	height: 100vh;

	.SuccessInner {
		@keyframes enter {
			from {
				opacity: 0;
				transform: translateY(30px);
			}
			to {
				opacity: 1;
				transform: translateY(0px);
			}
		}

		opacity: 0;
		transform: translateY(30px);
		animation-name: enter;
		animation-fill-mode: forwards;
		animation-timing-function: ease;
		animation-duration: 1.2s;

		.Top {
			@include flex-center-center;
			margin-bottom: 10px;

			.IconWrapper {
				svg {
					@include banner-txt-default;
					color: map-get($colors, color_green);
					margin-right: 10px;
				}
			}

			h1 {
				@include banner-txt-default;
				margin: 0;
			}
		}

		p {
			@include p-default;
			text-align: center;
		}

		@media #{map-get($media, tablet_max)} {
			.Top {
				@include flex-col-center-center;

				.IconWrapper {
					svg {
						padding: 0 !important;
						font-size: 8rem !important;
						margin-right: 0 !important;
					}
				}
				h1 {
					text-align: center;
				}
			}
		}
	}
}
