@import 'sass';

.Wrapper {
	@include flex-col-start;
	background-color: map-get($colors, color_themeWhite);
	// background-color: map-get($colors, color_defaultBlack);
	border-radius: map-get($borders, borders_small_radius);
	padding: 20px;
	margin: 20px 0;

	h3,
	p {
		// color: white;
		color: map-get($colors, color_themeBlack);
	}

	h3 {
		@include sub-default;
		margin: 0;
	}

	p {
		@include p-small;

		overflow: hidden;
	}

	.Description {
		height: 65px;
	}
}

.Container {
	@include flex-start;
}

.RatingBar {
	@include flex-col-center;

	.LabelWrapper {
		@include flex-start;

		p {
			@include p-small;
			margin-bottom: 4px;
		}
	}

	.MeterOuter {
		height: 5px;
		width: 100%;
		border-radius: 10px;
		background-color: map-get($colors, color_themeBlack);
		position: relative;
		overflow: hidden;
		margin-bottom: 10px;

		.MeterInner {
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 10px;
			background-color: map-get($colors, color_primary);
		}
	}
}
