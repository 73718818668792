@import 'sass';

@keyframes roll {
	from {
		margin-left: 0px;
	}
	to {
		margin-left: var(--total-logo-width);
	}
}

.Outer {
	@include flex-center;
	position: relative;

	.Container {
		.IconWrapper {
			position: relative;
			padding: 20px 0;
			overflow: hidden;
			z-index: 3;

			border: 1px solid map-get($colors, color_defaultBlack);
			border-radius: map-get($borders, borders_small_radius);

			.IconInner {
				@include flex-center;
				flex-wrap: nowrap;
				width: var(--double-logo-width);

				&:first-child {
					animation: roll var(--animation-duration) linear infinite;
				}
			}
		}
	}
	.ThinBar {
		position: absolute;
		height: 2px;
		width: 100%;
		z-index: 2;
		background-color: map-get($colors, color_defaultBlack);
	}
	@media #{map-get($media, mobile)} {
		.Container {
			margin: 0 !important;
			padding: 20px;
		}

		.ThinBar {
			display: none;
		}
	}
}
