@import 'sass';

.Container {
	padding: 20px 0;

	.Description {
		padding: 10px 20px;
		p {
			@include p-default;
		}
	}
}
.FocusModalContent {
	@include flex-center;
	position: relative;
}
.SliderBtn {
	@include no-select;
	@include hover-cursor;
	position: absolute;
	z-index: 100;
	font-size: 3rem;
	color: map-get($colors, color_themeBlack);
}
.PrevIconWrapper {
	left: -10px;
}

.NextIconWrapper {
	right: -10px;
}

.MockupThumbnail {
	@include hover-cursor;
	transition: 0.18s;
	margin-bottom: 25px;

	&:hover {
		transform: scale(1.025);
		@include shadow-default;
	}
}
