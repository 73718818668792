@import 'sass';

.Container {
	position: fixed;
	z-index: 802;
	bottom: 0;
	left: 0;
	width: 0;
}

.ContactWrapper {
	@include flex-center;
	position: fixed;
	z-index: 121;
	right: 0px;
	bottom: 0px;
	opacity: 1;
	transition: 0.2s;
	transition-timing-function: ease-in-out;

	&[data-toggle-open='1'],
	&[data-contact-open='1'] {
		opacity: 0;
	}

	.ContactBtn {
		padding: 19px 15px 10px 22px !important;
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		border-top-left-radius: 40px !important;
		background-color: map-get($colors, color_primary) !important;

		svg {
			font-size: 26px !important;
			color: map-get($colors, color_themeBlack);
		}
	}
}

.Container,
.ContactWrapper {
	@media #{map-get($media, cpu_min)} {
		display: none !important;
	}
}
