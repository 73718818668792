@import 'sass';

.Container {
	position: fixed;
	z-index: 801;
	width: 100%;

	.ContainerInner {
		@include flex-center;
		position: relative;
		top: 0;
		left: 0;
		transition: 0.3s;
		height: map-get($sizes, sizes_nav_height);

		&[data-scrolled='1'],
		&[data-is-home='0'] {
			background-color: map-get($colors, color_defaultBlack);
		}

		@keyframes enter-button {
			from {
				top: -100px;
				left: -100px;
			}
			to {
				top: 0;
				left: 0;
			}
		}

		.Home {
			@include hover-cursor;

			position: absolute;
			left: 110px;
			top: 28px;

			svg {
				color: white;
				font-size: 20px;
			}

			&[data-is-home='1'] {
				display: none;
			}

			&:hover {
				transform: scale(1.03);
			}
		}
		.BackButton {
			@include hover-cursor;

			position: absolute;
			z-index: 1002;
			width: 100px;
			height: 100px;
			top: -100px;
			left: -100px;
			transition: 0.25s;
			animation: enter-button 0.4s ease forwards;

			.BtnContent {
				@include flex-center;
				padding: 30px 0 0 20px;
				position: absolute;

				svg,
				p {
					@include p-small;
				}
			}

			&[data-is-home='1'] {
				display: none;
			}

			&:hover {
				path {
					@include shadow-default;
				}
				transform: scale(1.1);
			}
		}

		.CenterLinks,
		.RightLinks {
			@include flex-center-center;
			height: 100%;
		}

		.CenterLinks {
			margin: 0 auto;
		}

		.RightLinks {
			position: absolute;
			right: 20px;
		}
	}

	@media #{map-get($media, tablet_max)} {
		display: none !important;
	}
}
