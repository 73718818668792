@import 'sass';

.Outer {
	@include flex-center;
	padding-bottom: 30px;

	.Inner {
		padding: 15px 30px;
		z-index: 10;

		border-radius: map-get($borders, borders_large_radius);
		background-color: map-get($colors, color_defaultBlack);

		h3,
		p {
			color: white;
			margin: 0;
		}

		h3 {
			@include p-default;
		}

		p {
			@include p-small;
			margin-bottom: 5px;
		}

		.Grid {
			width: 100%;
			.Links {
				padding: 10px 0px;
			}

			.Right {
				@include flex-col-center-center;

				.Logos {
					@include flex-center-center;
				}
			}
		}
	}

	.ThinBar {
		position: absolute;
		z-index: 5;
		width: 100vw;
		height: 4px;
		background-color: map-get($colors, color_defaultBlack);
	}

	@media #{map-get($media, tablet_max)} {
		padding-bottom: 0 !important;

		.Inner {
			@include flex-col-center-center;
			border-radius: 0 !important;
			padding-bottom: 85px;

			p {
				font-size: 16px !important;
				margin: 0 !important;
				padding: 5px 0 !important;
			}
		}

		.ThinBar {
			display: none;
		}
	}
}
