@import 'sass';

.Modal {
	border-radius: map-get($borders, borders_small_radius) !important;
	opacity: 0;
	transform: translateY(15px);

	@keyframes enterModal {
		from {
			opacity: 0;
			transform: translateY(15px);
		}
		to {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	animation-name: enterModal;
	animation-duration: 0.18s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;

	.Header {
		background-color: map-get($colors, color_primary) !important;
		border-top-left-radius: calc(#{map-get($borders, borders_small_radius)}) !important;
		border-top-right-radius: calc(#{map-get($borders, borders_small_radius)}) !important;

		.Title {
			@include sub-default;
			color: map-get($colors, color_themeBlack);
			font-weight: 700 !important;
			margin: 0 !important;
		}
	}

	.ContentWrapper {
		border-bottom-left-radius: map-get($borders, borders_small_radius) !important;
		border-bottom-right-radius: map-get($borders, borders_small_radius) !important;

		.Inner {
			padding: 15px 25px 30px 25px;
		}
	}
}
