@import 'sass';

.Wrapper {
	@include flex-center-center;
	height: 100vh;
	width: 100vw;
	overflow: hidden;

	h1 {
		@include banner-txt-default;
	}

	p {
		@include p-default;
	}

	&[data-bottom-border='1'] {
		border-bottom: solid 2px map-get($colors, color_themeBlack);
	}

	@media #{map-get($media, mobile)} {
		height: unset;

		h1 {
			padding: 0 !important;
			margin-bottom: 0 !important;
		}

		p {
			padding: 15px 0 20px 0;
		}

		.ChildrenWrapper {
			padding-top: 15px;
		}

		.SectionContent {
			padding: 80px 40px !important;
		}
	}
}
