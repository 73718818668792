@import 'sass';

.Divider {
	height: 5px;
	width: 100px;
	background-color: map-get($colors, color_primary);

	&[data-indent='1'] {
		margin-left: 20px;
	}

	&[data-space-bottom='1'] {
		margin-bottom: 25px;
	}
}
